
// 시작 - down
.slide-fade-leave, .slide-fade-leave-to, .slide-fade-leave-active{
    opacity: 0;
    transform: translateY(100%);
    transition: all .4s cubic-bezier(.77,0,.175,1);

}

// 끝 - up
.slide-fade-enter, .slide-fade-enter-to, .slide-fade-enter-active{
    opacity: 0;
    transform: translateY(100%);
    transition: all .8s cubic-bezier(.77,0,.175,1);
    animation: slide-up .8s cubic-bezier(.77,0,.175,1);
    animation-delay: 1.2s;
}
@keyframes slide-up{
    0%{
        opacity: 0;
        transform: translateY(100%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
